<!--我的设计-我的设计瀑布流-->
<template>
  <ul class="list-container">
    <li class="grid" v-for="(item, index) in props.items"  @mouseover="showstate=item.id" @mouseout="showstate=-1" >

      <div class="resource-item-v2">
        <div class="resource-thumbUrl-mask">
          <div class="resource-thumbUrl">
            <NuxtLink :to="'/designs/mycreate/1/id/' + item.id">
            <div v-show="showstate===item.id" class="masking"></div>
            <img :src="item.coverimg">
            </NuxtLink>
            <el-checkbox-group v-model="basicValsEx" @change="getCheckbox">
              <el-checkbox :value="item.id" v-show="batch" class="checkbox"
              ></el-checkbox>
            </el-checkbox-group>
            <client-only>
              <el-popover
                  popper-class="designWaterfallpopover"
                  :show-arrow="false"
                  placement="bottom-start"
                  :width="100"
                  :offset="5"
                  @hide="getHideClose"
                  trigger="click">
                <template #reference >
                  <div>
                    <div v-if="typeMore!=='禁用'" v-show="item.id===imgIndex || item.id=== showstate" @click="getMenu(item.id)" class="templateBus"><i style="font-size: 20px;color: #FFFFFF" class="iconfont icon-gengduocaozuo"></i></div>
                  </div>
                </template>
                <div class="functionBox">
                  <div @click="getOperation(item,2)" class="function"><i class="iconfont icon-yidongdaowenjianjia1"></i>&nbsp; <span>移动到文件夹</span></div>
                  <div @click="getOperation(item,3)" class="function"><i class="iconfont icon-zhongmingming"></i>&nbsp; <span>重命名</span></div>
                  <div @click="getOperation(item,4)" class="function"><i class="iconfont icon-shanchu"></i>&nbsp; <span>删除</span></div>
                </div>
              </el-popover>
            </client-only>
          </div>
          <div class="resource-title">
            <div class="edit-name-wrap" style="width:100%;">
              <NuxtLink :to="'/designs/mycreate/1/id/' + item.id">
                <div class="title">
                  <p v-if="item.title =='' ">未命名</p>
                  <p v-else :title="item.title">{{item.title}}</p>
                </div>
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
      <div class="placeholder"></div>
    </li>
  </ul>


<!--    <div class="waterfall">-->
<!--      &lt;!&ndash; 循环渲染每一列 &ndash;&gt;-->
<!--      <div class="waterfall-column" v-for="(column, index) in columns" :key="index">-->
<!--        &lt;!&ndash; 循环渲染每一项 &ndash;&gt;-->
<!--        <div class="waterfall-item" v-for="(item, i) in column" :key="i">-->
<!--          <div class="template-container" @mouseover="showstate=item.id" @mouseout="showstate=-1"  >-->
<!--            <NuxtLink :to="'/designs/mycreate/1/id/' + item.id">-->
<!--              <div class="imgageBox">-->
<!--                <div v-show="showstate===item.id" class="masking"></div>-->
<!--                <img style="border-radius: 5px;width: 100%" :src="item.coverimg" />-->
<!--              </div>-->
<!--            </NuxtLink>-->

<!--            <el-checkbox-group v-model="basicValsEx" @change="getCheckbox">-->
<!--              <el-checkbox :value="item.id" v-show="batch" class="checkbox"-->
<!--                           ></el-checkbox>-->
<!--            </el-checkbox-group>-->
<!--            <client-only>-->
<!--            <el-popover-->
<!--                popper-class="designWaterfallpopover"-->
<!--                :show-arrow="false"-->
<!--                placement="bottom-end"-->
<!--                :width="170"-->
<!--                @hide="getHideClose"-->
<!--                trigger="click">-->
<!--              <div class="functionBox">-->
<!--                <div @click="getOperation(item,2)" class="function"><i class="iconfont icon-yidongdaowenjianjia1"></i>&nbsp; <span>移动到文件夹</span></div>-->
<!--                <div @click="getOperation(item,3)" class="function"><i class="iconfont icon-zhongmingming"></i>&nbsp; <span>重命名</span></div>-->
<!--                <div @click="getOperation(item,4)" class="function"><i class="iconfont icon-shanchu"></i>&nbsp; <span>删除</span></div>-->
<!--              </div>-->
<!--              <template #reference >-->
<!--                <div>-->
<!--                  <div v-if="typeMore!=='禁用'" @click="getMenu(item.id)" v-show="item.id===imgIndex || item.id=== showstate"  class="templateBus"><i   style="font-size: 20px;color: #FFFFFF" class="iconfont icon-gengduocaozuo"></i></div>-->
<!--                </div>-->
<!--              </template>-->
<!--            </el-popover>-->
<!--            </client-only>-->
<!--          </div>-->
<!--          <div class="image-container-title">{{item.title}}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
</template>
<script setup>
const props = defineProps({
  items: {
    type: Array,
    required: true
  },
  columnWidth: {
    type: Number,
    default: 200
  },
  gutter: {
    type: Number,
    default: 10
  },
  batchShow: {
    type: Boolean,
    required: true,
  },
  selectAllState: {}
});

</script>
<script>
export default {
  name: 'designWaterfallFlow',
  props: {
    items: {
      type: Array,
      required: true
    },
    columnWidth: {
      type: Number,
      default: 200
    },
    gutter: {
      type: Number,
      default: 10
    },
    batchShow: {
      type: Boolean,
      required: true,
    },
    selectAllState: {}
  },
  data() {
    return {
      columns: [], // 存储每一列的数据
      showstate: -1,
      batch: false,
      basicValsEx: [],
      imgIndex: -1,
      typeMore: "开启",
    }
  },
  watch: {
    items: {
      handler: function (newVal, oldVal) {
        //this.initColumns() // 监听数据变化，重新计算每一列的数据
      },
      deep: true,
    },
    batchShow(data) {
      this.batch = data
    },
    selectAllState(data) {
      this.typeMore = data.row
      this.getSelectAll(data.row)
    }
  },
  methods: {
    //关闭更多菜单
    getHideClose() {
      this.imgIndex = -1
    },
    getMenu(data) {
      this.imgIndex = data
    },
    //操作列表
    getOperation(data, name) {
      this.$emit('singleOperation', data, name)
    },
    //全选/取消选择
    getSelectAll(data) {
      if (data === '全选') {
        this.basicValsEx = []
        for (let e = 0; e < this.items.length; e++) {
          this.basicValsEx.push(this.items[e].id)
        }
      } else {
        this.basicValsEx = []
      }
      this.getCheckbox()
    },
    //选择
    getCheckbox() {
      this.$emit('select', this.basicValsEx,)
    }
  }
}
</script>
<style lang="less">
.designWaterfallpopover {
  --el-popover-padding: 0 !important;
}
.checkbox .el-checkbox__inner:after {
  height: 9px;
  left: 7px;
  top: 1px;
}

</style>
<style lang="less" scoped>
.el-tooltip__trigger{
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
<style scoped>
.image-container-title {
  margin-left: 10px;
  margin-top: 5px;
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #3b3946;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.functionBox {
  display: flex;
  flex-direction: column;
}

.functionBox .function:hover {
  background-color: #F5F5F5;
}

.functionBox .function {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 10px;
  color: #606266;
  font-size: 14px;
  cursor: pointer;
}

.template-container {
  position: relative;
  border-radius: 5px;
  margin-left: 10px;
}

.checkbox {
  position: absolute;
  top: 0;
  left: 10px;
  z-index: 0;
  --el-checkbox-input-height: 18px;
  --el-checkbox-input-width: 18px;
}

.templateBus {

  color: #333333;
  width: 27px;
  height: 22px;
  background-color: rgba(1, 1, 1, 0.6);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.showborder {
  border: 2px solid #81D8D0;
  border-radius: 5px;
}

.waterfall {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
  z-index: 0;
}

.waterfall-column {
  flex: 1;
  margin: 5px;
}

.waterfall-item {
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
}

.waterfall-item .text {
  position: absolute;
  z-index: 9999;
  top: 0px;
  right: 0px;
  left: 0px;
  text-align: center;
  background-color: rgba(30, 29, 29, 0.5); /* 设置背景颜色为白色，透明度为0.5 */
  color: aliceblue;
  transition: 0.3s;
  transform: translateY(-100px);
}

.waterfall-item .img {
  transition: 0.8s;
}

.waterfall-item .img:hover {
  transform: scale(1.2);
}

.waterfall-item:hover .text {
  transform: translateY(0px);
}

.imgageBox {
  position: relative;
}

.masking {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2); /* 半透明黑色遮罩 */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: calc(100% + 22px);;
}

.list-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.grid {
  position: relative;
  width: calc((100% - 16px * 8) / 8);
  margin: 20px 16px 32px 0;
}

.resource-item-v2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.resource-thumbUrl-mask {
  height: 100%;
  position: relative;
}

.resource-thumbUrl {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  padding: 12px;
  border-color: transparent;
  background-color: #f8f8fb;
  border-radius: 8px;
  overflow: hidden;
  transition: all .2s;
  cursor: pointer;

  img {
    width: unset;
    height: unset;
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0 5px 10px #d0d3d7;
    border-radius: 2px;
    transition: width .2s, height .2s;
    vertical-align: middle;
    border-style: none;
    overflow-clip-margin: content-box;
    overflow: clip;
  }
}
.placeholder{
  padding-bottom: 100%;
  height: 0;
}
.resource-title{
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.title{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 24px;
  border-bottom: 1px dashed transparent;
  p{
    max-width: calc(100% - 28px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    display: inline-block;
    font-size: 14px;
    color: #999;
    text-align: center;
  }
}
</style>
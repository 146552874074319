<!--我的设计-我的设计-->
<template>
  <div  class="collection">
    <div class="fixedFunction">
<!--      搜索-->
      <div class="myDesignSearch">
        <el-input size="large" @keyup.enter="handleEnter"  v-model="input1" style="width: 296px;margin-right: 10px;" placeholder="搜索我的设计">
          <template #suffix>
            <el-icon style="cursor: pointer" class="el-input__icon">
              <component :is="Search" @click="handleIconClick(input1)" />
            </el-icon>
          </template>
        </el-input>
        <client-only>
          <el-tooltip effect="dark" content="新建文件夹" placement="top">
            <el-button @click="getFolderAdd" class="newFolder"><i class="iconfont icon-xinjianwenjianjia"></i></el-button>
          </el-tooltip>
        </client-only>
        <el-progress type="circle" :width="40" :percentage="schedule" color="rgb(255, 149, 2)" :stroke-width="4" />
      </div>
      <!--面包屑-->
      <div v-show="folderBreadcrumb !== ''" class="breadcrumb">
        <el-breadcrumb  :separator-icon="ArrowRight">
          <el-breadcrumb-item @click="getReturn" class="breadcrumb-myDesign"><el-link type="primary" :underline="false"><span>我的设计</span></el-link></el-breadcrumb-item>
          <el-breadcrumb-item>{{folderBreadcrumb}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 我的设计img列表-->
    <div class="imageList">
      <div class="myDesignImageList" @scroll="handleScroll"  >
          <!--文件夹-->
          <div v-show="folderShow" class="folderList" >
            <div class="folderBox" v-for="(item,index) in folderData" @mouseenter="showfile=index" @mouseleave="showfile=-1">
              <div class="iconBox">
                <img @click="getOpenFolder(item)"  src="/editor/file.png" alt="">
                <span style="cursor: pointer;">{{item.title}}</span>
                <client-only>
                  <el-popover
                      :persistent="false"
                      :disabled="folderDisabled"
                      popper-class="popover"
                      :show-arrow="false"
                      placement="bottom-start"
                      :width="170"
                      trigger="click">
                    <div class="functionBox" @mouseenter="showfile=index" @mouseleave="showfile=-1">
                      <div class="function" @click="getFolderRename(item)">
                        <i class="iconfont icon-zhongmingming"></i>&nbsp; <span>重命名</span></div>
                      <div @click="getFolderDel(item)"  class="function">
                        <i class="iconfont icon-shanchu"></i>&nbsp; <span>删除</span></div>
                    </div>
                    <template #reference>
                      <div @click="folderDisabled=false" class="operation" v-show="showfile==index"><i style="font-size: 20px;color: #FFFFFF" class="iconfont icon-gengduocaozuo"></i></div>
                    </template>
                  </el-popover>
                </client-only>
              </div>
            </div>
          </div>
        <div class="plcz">
          <!-- 二级检索菜单-->
          <div class="screen">
            <div class="design">
            </div>
            <div class="btn-fam-box">
              <span>共计 <b>{{posterList.length}}</b> 个模版 &nbsp;</span>
              <client-only>
                <el-tooltip effect="dark" :content="switchListIf?'列表':'平铺'" placement="top">
                  <i v-if="switchListIf" @click="getSwitchList" style="font-size: 16px;margin-right: 10px;cursor: pointer" class="iconfont icon-liebiao"></i>
                  <i v-else @click="getSwitchList" style="font-size: 18px;margin-right: 10px;cursor: pointer" class="iconfont icon-mobanzhongxin2"></i>
                </el-tooltip>
              </client-only>
              <client-only>
                <el-tooltip effect="dark" content="批量操作" placement="top">
                  <i v-if="switchListIf" @click="getBatch" style="line-height:20px;font-size: 16px;margin-right: 10px;cursor: pointer" class="iconfont icon-group52"></i>
                </el-tooltip>
              </client-only>
            </div>
          </div>
          <!--          批量操作菜单-->
          <div class="batchOperations" v-show="batchShow === true ">
            <div class="batchOperations-left">
              <div @click="getSelectAll(number===0?'全选':'取消选择')" class="selectAll"><span v-show="number===0">全选</span><span v-show="number!==0">取消选择</span></div>
              <div class="line"></div>
              <div class="select">已选中 <span>{{number}}</span> 项</div>
            </div>
            <div class="batchOperations-right">
              <div @click="getatchMove" :class="number===0?'disabled':'folder'"  > <i class="iconfont icon-a-Group553"></i> 移动到文件夹</div>
              <div @click="getDelete('删除后作品将无法进行找回,请谨慎操作!','确定要删除 '+number+' 个作品吗?','batchDeletionImg')" :class="number===0?'disabled':'folder'" >
                <i class="iconfont icon-shanchu"></i> 批量删除</div>
              <div class="line"></div>
              <div @click="getBatch" class="folder close ">关闭</div>
            </div>
          </div>
        </div>
        <div class="group-line"></div>

        <div class="empty" v-show="posterList.length===0 && !showloging">
          <img src="/img/nodata.png" alt="">
          <span>您还没有设计模板</span>
          <span>去模板中心看看有没有喜欢的模板吧！</span>
          <NuxtLink class="goTemplate" :to="'/templateCenter'"><el-button type="primary" size="large" >前往模板中心</el-button></NuxtLink>
        </div>
        <FullLoading v-if="showloging" />
        <div v-else>
          <!--图片瀑布流-->
          <design-waterfall-flow v-if="switchListIf" @singleOperation="singleOperation" @select="select($event)" :selectAllState="selectAllState" :items="posterList" :batchShow="batchShow" :column-width="200" :gutter="40"></design-waterfall-flow>
          <!--图片表格-->
          <div v-else>
            <el-table class="desigSubTable" stripe :data="posterList" style="width: 100%;">
              <el-table-column align='left' label="名称">
                <template #default="scope">
                  <div class="tableTitle">
                    <el-image  :preview-src-list="srcList" :initial-index="1" style="width: 40px;height: 40px;border-radius: 4px;" :src="scope.row.coverimg" alt=""></el-image>
                    <span v-if="scope.row.title ==''">未命名</span>
                    <span v-else>&nbsp;{{scope.row.title}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align='center'  label="尺寸">
                <template #default="scope">
                  <span>{{scope.row.width}}×{{scope.row.height}}px</span>
                </template>
              </el-table-column>
              <el-table-column align='center' prop="size" label="大小" width="180"/>
              <el-table-column align='center' prop="create_time" label="更新时间" width="180" />
              <el-table-column align='right'  label="操作">
                <template #default="scope">
                  <client-only>
                    <el-popover
                        popper-class="designWaterfallpopover"
                        :show-arrow="false"
                        placement="bottom-end"
                        :width="170"
                        trigger="click">
                      <div class="functionBox">
                        <NuxtLink :to="'/designs/mycreate/1/id/' + scope.row.id"><div @click="" class="function"><i class="iconfont icon-wodesheji"></i>&nbsp;<span>编辑</span></div></NuxtLink>
                        <div @click="singleOperation(scope.row,2)" class="function"><i class="iconfont icon-yidongdaowenjianjia1"></i>&nbsp; <span>移动到文件夹</span></div>
                        <div @click="singleOperation(scope.row,3)" class="function"><i class="iconfont icon-zhongmingming"></i>&nbsp; <span>重命名</span></div>
                        <div @click="singleOperation(scope.row,4)" class="function"><i class="iconfont icon-shanchu"></i>&nbsp; <span>删除</span></div>
                      </div>
                      <template #reference >
                        <i style="font-size: 22px;cursor: pointer;" class="iconfont icon-gengduocaozuo"></i>
                      </template>
                    </el-popover>
                  </client-only>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="tablePage">
            <!--分页-->
            <el-pagination v-show="showpage" :page-size="pageSize" @change="changepage" class="paging" defailt background :total="total" v-model:current-page="page" layout="prev, pager, next "></el-pagination>
          </div>
        </div>

      </div>
    </div>
    <!--    我的设计-增加文件夹/重命名弹窗-->
    <client-only>
      <el-dialog
          @closed="getDiaClose"
          :close-on-click-modal="false"
          v-model="diaFolderAdd"
          :title="addTitle"
          width="500">
        <el-input v-if="addTitle!=='请选择要移入的文件夹'" show-word-limit maxlength="20" type="text" size="large"
                  v-model="folderAddInput" style="width: 100%" :placeholder="addPlaceholder" />
        <el-select
            v-if="addTitle==='请选择要移入的文件夹'"
            v-model="folderAddSelect"
            :placeholder="addPlaceholder"
            size="large"
            style="width: 100%">
          <el-option
              v-for="item in folderData"
              :key="item.id"
              :label="item.title"
              :value="item.id"
          />
        </el-select>

        <template #footer>
          <div class="dialog-footer">
            <el-button type="primary" @click="folderAddConfirm(addTitle)">确定
            </el-button>
          </div>
        </template>
      </el-dialog>
    </client-only>
  </div>
</template>
<script lang="ts" setup>
import { Search } from '@element-plus/icons-vue'
import { ArrowRight } from '@element-plus/icons-vue';
import {ElMessage, ElMessageBox} from "element-plus";
import DesignWaterfallFlow from "./designWaterfallFlow.vue";
import {
  getDeleteMyTemplate,
  getMyTemplateTitle,
  getMytTemplateGroup,
  getTemplateMyDesignList,
  getUploadGroupAdd, getUploadGroupDel,
  getUploadGroupList,
  getUploadGroupModify
} from "../../api/apiconfig/main";
import {getLocal} from "../../utils/local";
import { ElTable } from 'element-plus'
//分页总条数
const showloging = ref(true);
const showfile = ref(-1);
const total = ref(0)
const pageSize = ref(1)
const showpage = ref(true);
const switchListIf = ref(true)
const handleData = ref()
const loading = ref(false);
const isLoadingEnd = ref(false)
const typeValue = ref(0)
const page = ref(1);
const schedule = ref(100);
//批量选中值
const batchData = ref()
//重命名赋值
const folderRenameID = ref()
//单选图片ID
const imgID = ref()
//搜索变量
const input1 = ref('')
//dia弹窗变量
const diaFolderAdd = ref(false)
//dia弹窗标题
const addTitle = ref()
//select提示文字
const addPlaceholder  = ref()
//input绑定变量
const folderAddInput = ref('')
//面包屑是否显示变量
const folderBreadcrumb= ref('')
const folderDisabled = ref(false);
const judgeId = ref();
const srcList = ref([])
//批量操作是否显示
const batchShow = ref(false)
//文件夹是否显示
const folderShow = ref(true)
//图片列表
const  posterList  = ref([])
//瀑布流全选传值变量
const selectAllState = ref({Object:Object})
//图片列表是否选择图片
const number = ref(0)
//键盘确定按键触发方法
const handleEnter = (()=>{
  if(handleData.value !== input1.value){
    getReset()
    getImgList()
    handleData.value = input1.value
  }
})

//文件夹数组
const folderData = ref([])
//
const folderAddSelect = ref('')
// 子文件事件(批量选中的值)
const select = ((row)=>{
  batchData.value = row
  number.value = row.length
})
//弹窗关闭触发事件
const getDiaClose = (()=>{
  folderAddSelect.value = ''
  folderAddInput.value = ''
})
//新增文件夹
const getFolderAdd = (()=>{
  getDiaBus('新增文件夹','请输入文件夹名称','','addFolder')
});
//弹窗的公共事件
const getDiaBus = ((data,data2,data3,data4)=>{
  //弹窗显示
  diaFolderAdd.value = true
  //弹窗标题
  addTitle.value= data
  //输入提示文字
  addPlaceholder.value = data2
  //绑定变量
  folderAddInput.value = data3
  //判断ID
  judgeId.value = data4
})
//切换列表
const getSwitchList = (()=>{
  switchListIf.value=!switchListIf.value
  selectAllState.value = {row:'取消选择'}
  batchShow.value = false
  number.value= 0
})
//dia弹出框确定
const folderAddConfirm = ((value)=>{
  let data = folderAddInput.value
  let id = imgID.value
  // if(folderAddInput.value===""){
  //   ElMessage({
  //     message: '请输入名称',
  //     type: 'warning',
  //   })
  // }else{
    //新增文件夹
    if(judgeId.value==='addFolder'){
      uploadGroupAdd()
      //重命名我的img作品
    }else if(judgeId.value==='renamingWork'){
      myTemplateTitle(data)
      //重命名文件夹
    }else if(judgeId.value==='foldersRenaming'){
      uploadGroupModify()
      //移动图片到文件夹
    }else if(judgeId.value==='moveImg'){
      mytTemplateGroup(imgID.value)
      //批量移动图片到文件夹
    }else if(judgeId.value==='movetoFolders'){
      let list =  computed(() => batchData.value.join(','));
      mytTemplateGroup(list.value)
      selectAllState.value = {row:'取消选择'}
      number.value= 0
    }
    diaFolderAdd.value= false
  // }
});
//重命名图片名称
const myTemplateTitle = (async (data)=>{
  const response = await getMyTemplateTitle({tid:imgID.value,title:folderAddInput.value})
  if (response.code === 1) {
    ElMessage.success(response.msg)
    posterList.value.forEach((item,index) => {
      if(item.id==imgID.value){
        posterList.value[index].title = data
      }
    });
  } else {
    ElMessage.error(response.msg)
  }
})
//修改单个/批量图片分组
const mytTemplateGroup = (async (id)=>{
  const response = await getMytTemplateGroup({tid:id,group_id:folderAddSelect.value})
  if (response.code === 1) {
    ElMessage.success(response.msg)
    //查看文件夹内容图片
    if(typeValue.value!==0){
      toRaw(posterList.value).forEach((item,index) => {
        if(item.id===imgID.value){
          posterList.value.splice(index, 1);
        }
      });
    }
  } else {
    ElMessage.error(response.msg)
  }
})
//重命名文件夹(接口)
const uploadGroupModify = (async () => {
  const response = await getUploadGroupModify({
    uid: getLocal('uid'), title: folderAddInput.value, id: folderRenameID.value,
  });
  if (response.code === 1) {
    ElMessage.success(response.msg)
    //刷新分组列表
    await getGroupList()
  } else {
    ElMessage.error(response.msg)
  }
})
//新建文件夹(接口)
const uploadGroupAdd = (async () => {
  const response = await getUploadGroupAdd({
    uid: getLocal('uid'), title: folderAddInput.value, type: 2,
  });
  if (response.code === 1) {
    ElMessage.success(response.msg)
    //刷新分组列表
    await getGroupList()
  } else {
    ElMessage.error(response.msg)
  }
})
//获取分组列表(接口)
const getGroupList = (async () => {
  const response = await getUploadGroupList({uid: getLocal('uid'), type: 2,});
  if(response){
    if (response.code === 1) {
      folderData.value = response.data
    } else {
      ElMessage.error(response.msg)
      folderData.value = []
    }
  }
})
//打开文件夹返回
const getReturn = (()=>{
  folderShow.value = true
  folderBreadcrumb.value=''
  typeValue.value = 0
  getReset()
  //获取新的列表
  getImgList()
})
//批量操作功能是否显示
const getBatch = (()=>{
  number.value= 0
  if(batchShow.value){
    batchShow.value = false
    selectAllState.value = {row:'开启'}
  }else{
    batchShow.value = true
    selectAllState.value = {row:'禁用'}
  }
})
//父文件传值(全选/取消选择)
const getSelectAll = ((row)=>{
  selectAllState.value = {row:row}
})
//打开文件夹
const getOpenFolder = ((data)=>{
  showloging.value = true;
  folderBreadcrumb.value=data.title
  typeValue.value = data.id
  //不显示文件夹列表
  folderShow.value = false
  posterList.value = []
  //关闭全选方法
  batchShow.value = false
  //获取新的列表
  getReset()
  getImgList()
  getSelectAll('取消选择')
})
//批量移动到文件夹
const getatchMove = (()=>{
  folderAddSelect.value = ""
  getDiaBus('请选择要移入的文件夹','请选择','','movetoFolders')
})
//文件夹重命名
const getFolderRename = ((data)=>{
  folderRenameID.value = data.id
  folderDisabled.value = true
  getDiaBus('重命名文件夹','请输入文件夹名称',data.title,'foldersRenaming')
})
//文件夹删除
const getFolderDel = ((data)=>{
  folderRenameID.value = data.id
  folderDisabled.value = true
  getDelete('删除文件夹后,该文件夹中的所有内容依然会保存至你的作品中,请放心操作','确定要删除文件夹吗?','foldersRemoving')
})
//子文件事件(单项操作/增加/删除/重命名)
const singleOperation = ((data,name)=>{
  imgID.value = data.id
  // const list = JSON.parse(JSON.stringify(state.posterList))
  if(name===1){
  }
  if(name===2){
    getDiaBus('请选择要移入的文件夹','请选择','','moveImg')
  }
  if(name===3){
    getDiaBus('重命名我的作品','',data.title,'renamingWork')
  }
  if(name===4){
    getDelete('删除后作品将无法进行找回,请谨慎操作!','确定要删除作品吗?','individualDeletion')
  }
})
//搜索
const handleIconClick = ((data: any)=>{
  getReset()
  getImgList()
});
//重置分页/列表
const getReset = (()=>{
  page.value = 1
  posterList.value = []
})
//删除
const getDelete = (data,data2,data3) => {
  ElMessageBox.confirm(
      data,
      data2,
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
      }
  )
      .then(() => {
        //单个作品删除
        if(data3==='individualDeletion'){
          deleteMyTemplate(imgID.value,1)
          //文件夹删除
        }else if(data3==='foldersRemoving'){
          getUploadGroupDel({uid: getLocal('uid'), id: folderRenameID.value,}).then(response => {
            if (response.code === 1) {
              ElMessage.success(response.msg)
            } else {
              ElMessage.error(response.msg)
            }
          })
          folderData.value.forEach((item: { id: any; }, index: any)=>{
            if(item.id===folderRenameID.value){
              folderData.value.splice(item, 1);
            }
          })
        //  批量删除图片
        }else if(data3==='batchDeletionImg'){
          let list =  computed(() => batchData.value.join(','));
          deleteMyTemplate(list.value,2)
        }
      })
      .catch(() => {
        ElMessage({
          type: 'info',
          message: '取消删除',
        })
      })
}
//img作品批量/单独删除(接口)
const deleteMyTemplate = (async (id: any, sort: number) => {
  const response = await getDeleteMyTemplate({tid:id});
  if(response){
    if (response.code === 1) {
      if(sort===1){
        //单个删除图片
        toRaw(posterList.value).forEach((item: { id: any; }, index: any) => {
          if(item.id===imgID.value){
            posterList.value.splice(index, 1);
          }
        });
      }else if(sort===2){
        //批量删除图片
        let data = JSON.parse(JSON.stringify(posterList.value))
        let list = JSON.parse(JSON.stringify(batchData.value))
        let newData = data.filter(
            (a: { id: any; }) => !list.some((b: any)=>a.id === b)
        )
        posterList.value = newData
        selectAllState.value = {row:'取消选择'}
        number.value= 0
      }
      ElMessage.success(response.msg)

    } else {
      ElMessage.error(response.msg)
    }
  }
})
//初始化
onMounted(() => {
  getGroupList()
  getImgList()
});
//图片列表滚动方法
const handleScroll = (event: { target: any; }) => {
  const container = event.target;
  const { scrollTop, scrollHeight, clientHeight } = container;
  if (scrollTop + clientHeight >= scrollHeight - 1000) { // 当滚动到底部 1000px 时加载新数据
    if(isLoadingEnd.value === false){
      getImgList()
    }
  }
};
//分页跳转页面
const changepage = () =>{
  getImgList()
}

//获取我的设计图片列表
const getImgList = async ()=>{
  if (loading.value) return; // 如果已经在加载，则不执行
  loading.value = true; // 设置加载状态为 true
  try {
    const res = await getTemplateMyDesignList(
        {search:input1.value,uid:getLocal('uid'),page:page.value,group_id:typeValue.value});
    if(res.data.list.length === 0){
      isLoadingEnd.value = true;
      showpage.value = false;
    }else{
      isLoadingEnd.value = false;
      posterList.value = res.data.list;
      if(res.data.total >  res.data.pagesize){
        showpage.value = true;
      }else{
        showpage.value = false;
      }
    }
    total.value = res.data.total
    pageSize.value = res.data.pagesize
    schedule.value = res.data.schedule
    showloging.value = false;
  } finally {
    showloging.value = false;
    loading.value = false; // 请求完成后，设置加载状态为 false
  }
}
</script>
<style >
.popover {
  --el-popover-padding:unset !important;
}
.desigSubTable.el-table .el-table__cell{
  position: unset !important;
}
.popover .function{
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 10px;
  color: #606266;
  font-size: 14px;
  cursor: pointer;
}
.popover .function:hover{
  background-color: #F5F5F5;
}
</style>
<style lang="less" scoped>
:deep(.el-input__wrapper){
  background: #ebeef5;
  box-shadow: none;
  border: none;
}
.collection{
  width: 98%;
  //z-index: 2;
  min-width: 1200px;
  position: relative;
  .fixedFunction{
    z-index: 1;
    //position: -webkit-sticky; /* Safari */
    //position: sticky;
    //top: 50px; /* 设置固定位置为顶部 */
    margin-top: 30px;
    background-color: #FFFFFF;
    .myDesignSearch{
      display: flex;
      justify-content: right;
      margin-top: -30px;
      .newFolder{
        background: #ebeef5;
        border: none;
        width: 40px;
        height: 40px;
        font-size: 25px;
        margin-right: 10px;
        padding: 0px;
        font-weight: bold;
      }
    }
    .breadcrumb{
      display: flex;
      align-items: center;
      height: 50px;
      width: 100%;
      background-color: #FFFFFF;
      .breadcrumb-myDesign:hover{
        span:hover{
          color:#923D5E;
        }
      }
      .breadcrumb-myDesign{
        span{
          cursor: pointer;
        }
      }
    }
  }
  .screen{
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;
    justify-content: space-between;
    background-color: #FFFFFF;
    .design{
      display: flex;
    }
    span{
      font-size: 14px;
      color: #333333;
      user-select: none;
    }
    .collectionType{
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      .type:hover{
        background-color: #F5F2FF;
        cursor: pointer;
        color:var(--el-color-primary);
      }
      .type{
        padding: 0 5px;
        background-color: #FFFFFF;
        border-radius: 4px;
        font-size: 14px;
        margin-right: 10px;
        margin-top: 10px;
        font-weight: 400;
        color: #3b3946;
        line-height: 32px;
      }
    }
  }
  .batchOperations{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(245,247,253,.8);
    width: 100%;
    height: 40px;
    border-radius: 4px;
    padding: 10px;
    .batchOperations-left{
      width: 200px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: left;
      .selectAll:hover{
        background: #E9E9E9;
      }
      .selectAll{
        color:var(--el-color-primary);
        font-size: 14px;
        font-weight: 600;
        padding: 4px 5px;
        border-radius: 5px;
        cursor: pointer;
      }
      .line{
        width: 2px;
        height: 15px;
        background: #333333;
        line-height: 40px;
        margin-left: 10px;
      }
      .select{
        color: #333333;
        font-size: 14px;
        margin-left: 10px;
        span{
          color:var(--el-color-primary);
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
    .batchOperations-right{
      width: 300px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: right;
      .folder:hover{
        background: #E9E9E9;
      }
      .folder{
        color: #3b3946;
        font-weight: bold;
        font-size: 14px;
        margin-right: 10px;
        padding: 4px 5px;
        border-radius: 5px;
        cursor: pointer;
      }
      .disabled{
        opacity: 0.5;
        pointer-events: none;
        cursor: default;
        color: #3b3946;
        font-weight: bold;
        font-size: 14px;
        margin-right: 10px;
        padding: 4px 5px;
        border-radius: 5px;
      }
      .line{
        width: 2px;
        height: 15px;
        background: #333333;
        line-height: 40px;
        margin-right: 10px;
      }
      .close{
        color:#3b3946;
        font-size: 14px;
        font-weight: 600;
        padding: 4px 5px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
  .imageList{
    width: 100%;
    min-width: 1200px;
    background-color: #FFFFFF;
    .myDesignImageList{
      padding: 0px;
      -ms-overflow-style: none;
      position: relative;
      scrollbar-width: none;
      .folderList{
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        .folderBox{
          margin-top: 20px;
          border-radius: 5px;
          width: 170px;
          margin-right: 20px;
          background-color: #FFFFFF;
          display: flex;
          flex-direction: column;
          align-items: center;
          .iconBox:hover{
            background-color: #EBEBEB;
          }
          .iconBox{
            border-radius: 5px;
            width: 170px;
            height: 80px;
            background-color: #F5F5F6;
            display: flex;
            align-items: center;
            position: relative;
            img{
              cursor: pointer;
              width: 40px;
              height: 40px;
              margin-left: 20px;
              margin-right: 5px;
            }
            .functionBox{
              display: flex;flex-direction: column;
            }
            .operation{
              width: 25px;
              height: 20px;
              background-color: rgba(1, 1, 1, 0.6);
              color:#333333;
              position: absolute;
              margin-left: 133px;
              margin-top: 35px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              border-radius: 5px;
            }
          }
          span{
            line-height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #3b3946;
            margin-top: 10px;
          }
        }
      }
      .tablePage{
        display: flex;
        justify-content: center;
        margin-top: 40px;
      }
    }
    .empty{
      width: 100%;
      height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img{
        width: 126px;
        height: 126px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      span{
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 15px;
        color:#999;
      }
      .goTemplate{
        margin-top: 15px;
      }
    }
  }
}
.functionBox{
  display: flex;
  flex-direction: column;
}
.functionBox .function:hover{
  background-color: #F5F5F5;
}
.functionBox .function{
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 10px;
  color: #606266;
  font-size: 14px;
  cursor: pointer;
}
.tableTitle{
  display: flex;
  align-items: center;
  span{
    margin-left: 10px;
  }
}
.group-line{
  width: 100%;
  height: 0.5px;
  background: #ebeef5;
  margin: 15px 0 20px 0;
}
:deep(.el-progress__text){
  font-size: 14px !important;
  padding-left: 6px;
  text-align: left;
}
:deep(.el-input .el-input__count .el-input__count-inner){
  background: #ebeef5;
}
.btn-fam-box{
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 10px;
}
</style>

<!--我的设计-->
<template>
  <div id="appMain">
    <PanelHeader></PanelHeader>
    <main>
      <PanelLeft></PanelLeft>
      <div class="panel-mainContent">
<!--        固定tab菜单-->
        <div class="box">
<!--          tab菜单-->
            <div class="tab">
              <div v-for="(item,index) in titleData">
                <span :class="tabShow===item.value?'lineBottom':''" @click="getTabs(item.value)" class="characters">{{item.value}}</span>
              </div>
            </div>
        </div>
<!--        <collection  v-if="tabShow==='收藏'"></collection>-->
        <design-sub v-if="tabShow==='我的设计'" :key="tabShow"></design-sub>
        <footer class="panel-footer">
          <public-footer></public-footer>
        </footer>
      </div>
    </main>
  </div>
</template>
<script lang="ts" setup>
import PanelHeader from "/layout/components/PanelHeader.vue";
import PanelLeft from "/layout/components/PanelLeft.vue";
import DesignSub from "./designSub.vue";
import Collection from "./collection.vue";
import PublicFooter from "@/src/components/PublicFooter/index";
const tabShow = ref('我的设计')
const titleData = ref([
  {value:"我的设计",id:1},
])
const getTabs = ((data)=>{
  tabShow.value = data
})
useHead({
  title:'我的设计_松鼠快图_松鼠快图官网_在线图片编辑_在线作图网站',
  meta:[
    {hid:'keywords',name:'keywords','content':'我的设计,松鼠快图'},
    {hid:'description',name:'description','content':'松鼠快图我的设计页面。'},
  ]
})

</script>
<style lang='less' scoped>
#appMain {
  display: flex;
  flex-direction: column;
  height: 100%;
  main {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    overflow: hidden;
    .panel-mainContent {
      width: 100%;
      padding: 0 20px;
      overflow: auto;
      user-select: none;
      .box{
        user-select: none;
        z-index: 1;
        padding-top: 10px;
        min-width: 1200px;
        width: 98%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0; /* 设置固定位置为顶部 */
        background-color: #FFFFFF;
          .tab{
            display: flex;
            user-select: none;
            .characters:hover{
              color:var(--el-color-primary);
            }
            .characters{
              line-height: 40px;
              font-size: 14px;
              font-weight: 700;
              color: #303133;
              margin-right: 35px;
              padding-bottom: 7px;
              cursor: pointer;
            }
            .lineBottom{
              border-bottom: var(--el-color-primary) 2px solid;
              color:var(--el-color-primary);
            }
          }
      }
    }
  }
}
</style>
